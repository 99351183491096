import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  // Container,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  // Paper,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
// import VideocamIcon from '@material-ui/icons/Videocam';
import AddIcon from '@material-ui/icons/Add';
// import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import DepositIcon from '@material-ui/icons/CreditCard';
import ScheduleIcon from '@material-ui/icons/DateRange';
import ChatIcon from '@material-ui/icons/ForumOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import {
  blue,
  orange,
  deepOrange,
  // purple,
  // pink,
  // green,
  lightBlue,
  lightGreen,
  red,
} from '@material-ui/core/colors';

import UpcomingCall from './UpcomingCall';
import {
  logout,
  getPracticeRoomList
} from '../../actions';

import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';

import './css/NavigationBarWrapper.css';
import TutoredStudents from './TutoredStudents';
import ControlRoomButton from '../ControlRoomScreen/ControlRoomButton';

const drawerWidth = 240;
const studentSidbarBtns = [
  { color: blue[500], label: 'dash.home', path: '/dash', icon: <HomeIcon />, activeIcon: <HomeIcon style={{ color: blue[500] }} /> },
  // { color: purple[500], label: 'dash.joinCall', path: '/join-call', icon: <AddIcon />, activeIcon: <AddIcon style={{ color: purple[500] }} />  },
  // { color: pink[500], label: 'dash.wallet', path: '/wallet', icon: <WalletIcon />, activeIcon: <WalletIcon style={{ color: pink[500] }} />  },
  // { color: green[500], label: 'dash.deposit', path: '/quick-deposit', icon: <DepositIcon />, activeIcon: <DepositIcon style={{ color: green[500] }} />  },
  { color: lightBlue[500], label: 'dash.schedule', path: '/schedule', icon: <ScheduleIcon />, activeIcon: <ScheduleIcon style={{ color: lightBlue[500] }} />  },
  { color: lightGreen[500], label: 'dash.chat', path: '/chat', icon: <ChatIcon />, activeIcon: <ChatIcon style={{ color: lightGreen[500] }} />  },
  // { color: lightBlue[500], label: 'dash.profile', path: '/profile', icon: <AccountBoxIcon />, activeIcon: <AccountBoxIcon style={{ color: lightBlue[500] }} />  },
  { color: lightBlue[500], label: 'dash.settings', path: '/settings', icon: <SettingsIcon />, activeIcon: <SettingsIcon style={{ color: lightBlue[500] }} />  },
  { color: red[500], label: 'dash.logout', path: '/logout', icon: <PowerIcon color="secondary" />  }
];

const teacherSidbarBtnsWithLesson = [
  { color: blue[500], label: 'dash.home', path: '/dash', icon: <HomeIcon />, activeIcon: <HomeIcon style={{ color: blue[500] }} /> },
  { color: orange[500], label: 'dash.startCall', path: '/start-lesson-new', icon: <AddIcon />, activeIcon: <AddIcon style={{ color: orange[500] }} /> },
  // { color: purple[500], label: 'dash.joinCall', path: '/join-call', icon: <AddIcon />, activeIcon: <AddIcon style={{ color: purple[500] }} />  },
  // { color: pink[500], label: 'dash.wallet', path: '/wallet', icon: <WalletIcon />, activeIcon: <WalletIcon style={{ color: pink[500] }} />  },
  // { color: green[500], label: 'dash.deposit', path: '/quick-deposit', icon: <DepositIcon />, activeIcon: <DepositIcon style={{ color: green[500] }} />  },
  { color: lightBlue[500], label: 'dash.schedule', path: '/schedule', icon: <ScheduleIcon />, activeIcon: <ScheduleIcon style={{ color: lightBlue[500] }} />  },
  { color: lightGreen[500], label: 'dash.chat', path: '/chat', icon: <ChatIcon />, activeIcon: <ChatIcon style={{ color: lightGreen[500] }} />  },
  // { color: lightBlue[500], label: 'dash.profile', path: '/profile', icon: <AccountBoxIcon />, activeIcon: <AccountBoxIcon style={{ color: lightBlue[500] }} />  },
  { color: lightBlue[500], label: 'dash.settings', path: '/settings', icon: <SettingsIcon />, activeIcon: <SettingsIcon style={{ color: lightBlue[500] }} />  },
  { color: red[500], label: 'dash.logout', path: '/logout', icon: <PowerIcon color="secondary" />  }
];

const teacherSidbarBtnsWithoutLesson = [
  { color: blue[500], label: 'dash.home', path: '/dash', icon: <HomeIcon />, activeIcon: <HomeIcon style={{ color: blue[500] }} /> },
  // { color: purple[500], label: 'dash.joinCall', path: '/join-call', icon: <AddIcon />, activeIcon: <AddIcon style={{ color: purple[500] }} />  },
  // { color: pink[500], label: 'dash.wallet', path: '/wallet', icon: <WalletIcon />, activeIcon: <WalletIcon style={{ color: pink[500] }} />  },
  // { color: green[500], label: 'dash.deposit', path: '/quick-deposit', icon: <DepositIcon />, activeIcon: <DepositIcon style={{ color: green[500] }} />  },
  { color: lightBlue[500], label: 'dash.schedule', path: '/schedule', icon: <ScheduleIcon />, activeIcon: <ScheduleIcon style={{ color: lightBlue[500] }} />  },
  { color: lightGreen[500], label: 'dash.chat', path: '/chat', icon: <ChatIcon />, activeIcon: <ChatIcon style={{ color: lightGreen[500] }} />  },
  // { color: lightBlue[500], label: 'dash.profile', path: '/profile', icon: <AccountBoxIcon />, activeIcon: <AccountBoxIcon style={{ color: lightBlue[500] }} />  },
  { color: lightBlue[500], label: 'dash.settings', path: '/settings', icon: <SettingsIcon />, activeIcon: <SettingsIcon style={{ color: lightBlue[500] }} />  },
  { color: red[500], label: 'dash.logout', path: '/logout', icon: <PowerIcon color="secondary" />  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: 'none'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    border: 'none'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    backgroundColor: '#fff',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: '#fff',
  },
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: '#f6f8fa',
    marginTop: 64,
    justifyContent: 'center',
  },
  main: {
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    height: '100%',
  },
}));

const _NavigationBarWrapper = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const user = GLOBAL.get('user');

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
//  const [sidebarBtns, setSidebarBtns] = React.useState(AppHelper.isCBETeacher() ? teacherSidbarBtns : studentSidbarBtns);
  const [sidebarBtns, setSidebarBtns] = React.useState(AppHelper.isCBETeacher() ? (AppHelper.canDoFreeLesson() ? teacherSidbarBtnsWithLesson : teacherSidbarBtnsWithoutLesson) : studentSidbarBtns);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    props.logout();
  };

  React.useEffect(() => {
    const canAccessPracticeRoom = _.get(GLOBAL.get('appSettings'), 'canAccessPracticeRoom', false);
    // If user is subscribed for tutor room then update the sidebar
    if (canAccessPracticeRoom && sidebarBtns.indexOf((b) => b.path === '/tutor-room') === -1) {
      const tutorRoomBtn = { color: deepOrange[500], label: 'dash.tutorRoom', path: '/tutor-room', icon: <GroupIcon />, activeIcon: <GroupIcon style={{ color: deepOrange[500] }} /> };
      const newSidebar = sidebarBtns.slice();

      newSidebar.splice(1, 0, tutorRoomBtn);
      setSidebarBtns(newSidebar);
      props.getPracticeRoomList();
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        className={clsx('white-bg', classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="title" noWrap>
            { t('signin.welcome') }, {AppHelper.getFullname(user.firstName, user.lastName)}
          </Typography>
          <ControlRoomButton />
          <TutoredStudents />
          <UpcomingCall />
          <Avatar src={user.avatarUrl} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <React.Fragment>
            <img alt="logo" height="32px" width="32px" src={require('../../img/logo.png')} />
            <Typography variant="h6">{t('youbrio')}</Typography>
          </React.Fragment>
          
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>

        {
          // Removed for performance.
          // open ? 
          //   <Paper className="ghost-white-bg br-3 avatar-container mb-3" elevation={0}>
          //     <Avatar src={user.avatarUrl} />
          //     <Typography variant="body1">{AppHelper.getFullname(user.firstName, user.lastName)}</Typography>
          //   </Paper>
          //   :
          //   null
        }

        <List>
          {
            sidebarBtns.map((btn) => {
              const selected = btn.path === location.pathname;
              const isLogout = btn.label === 'dash.logout';

              return (
                <ListItem
                  button
                  key={btn.label}
                  selected={selected}
                  style={selected ? { borderRight: `4px ${btn.color} solid` } : {}}
                  onClick={() => (isLogout ? handleLogout() : history.push(btn.path))}
                >
                  <ListItemIcon>
                    {selected ? btn.activeIcon : btn.icon}
                  </ListItemIcon>
                  <ListItemText primary={t(btn.label)} style={(selected || isLogout) ? { color: btn.color } : {}} />
                </ListItem>
              );
            })
          }
        </List>
      </Drawer>
      <main className={`${classes.main}`}>
        <Grid container className={`${classes.gridContainer}`}>
          {/* inner fluid container */}
          <div className={`${classes.contentContainer}`}>
            { props.children }
          </div>
        </Grid>
        
        {/* outer container wrapper */}
        {/* <div className={`ghost-white-bg br-3 ${clsx([{'opened-outer-container': open, 'outer-container': !open}])}`} /> */}
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    practiceRooms: state.practiceRoom.practiceRooms
  };
};

export const NavigationBarWrapper = connect(mapStateToProps, {
  getPracticeRoomList,
  logout
})(_NavigationBarWrapper);
