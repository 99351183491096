import React, {
  useEffect
} from 'react';
import {
  Grid,
  Paper,
  Container,
  Typography,
  Button,
  // colors,
  // List,
  // ListItem,
  // ListItemText,
  CircularProgress,
  Backdrop,
  TextField,
} from '@material-ui/core';
// import { FixedSizeList } from 'react-window';
import 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Table,
  Dialog,
  Card,
  Layout,
  Button as EleButton,
  Message,
} from 'element-react';
import 'element-theme-default';
// import Draggable from 'react-draggable';
// import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {
// updateCommonStates,
// getTutorRoomStudentsTodayListAction,
} from '../../actions';
import {
  getTutorRoomStudentsTodayList,
  getPracticeRoomSomedayStatsList,
  updatePracticeRoomStudentProgressNote,
  updatePracticeRoomStudentHandoverNote,
  newPracticeRoomStudentProgressNote,
  newPracticeRoomStudentHandoverNote
  // getPracticeRoomFiles,
  // getPracticeRoomProfile,
} from '../../api';
// import GLOBAL from '../../helpers/GLOBAL';
import UserFilesDialog from '../UserFilesDialog/UserFilesDialog';
import FileUploader from '../UserFilesDialog/FileUploader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  paper: { width: '100%', marginTop: 40, marginBottom: 40, padding: 20 },
  table: { minWidth: 650 },
  subjectSelect: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  listItem: {
    width: '100%',
    border: '1px solid lightgrey',
    borderRadius: 6,
    marginTop: 32,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: 24,
  },
  avatarContainer: {
    display: 'flex',
    '& > *': {
      marginRight: 12,
      marginTop: 6,
      marginBottom: 6,
    },
  },
  refreshBtn: {
    marginLeft: 12
  },
  cell: {
    fontSize: '12px'
  }
}));

// const formatTimeStr = (seconds) => {
//   if (seconds === 0) { return seconds;}
//   const sec = seconds % 60;
//   const min = Math.floor((seconds / 60) % 60);
//   const hour = Math.floor(seconds / 3600);
//   var result = '';
//   if (hour) { result += hour + 'h';}
//   if (min) { result += min + 'm';}
//   if (sec) { result += sec + 's';}
//   return result;
// };

const createRowData = (student) => {
  var result =  {
    id: student.user_id,
    avatarUrl: student.avatar_url,
    firstName: student.first_name,
    lastName: student.last_name,
    name: student.first_name + ' ' + student.last_name,
    country: student.country,
    subject: student.subject,
    subjects: student.subjects, // For use when participant details is empty - for creating new handover notes
    // locked: (student.lockedBy ? 'Yes' : 'No') + ' ' + student.lockedBy,
    handoverNotes: student.handoverNotes,
    // lastEditedBy: student.lastEditedBy,
    objectives: student.objective,
    progressNotes: student.progressNotes,
    profiles: student.profile,
    has_files: student.has_files,
    participantDetails: student.participantDetails,
    // stats: {
    //   practiceTime: student.stats && formatTimeStr(student.stats.practice.totalTime),
    //   lessonTime: student.stats && formatTimeStr(student.stats.lesson.totalTime),
    //   totalTime: student.stats && formatTimeStr(student.stats.total.totalTime),
    // },
    value: student.first_name + ' ' + student.last_name // serach key
  };
  return result;
};

const TutoredStudentsScreen = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const [selectDate, setSelectDate] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [daysOffset, setDaysOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [studentList, setStudentList] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [statsList, setStatsList] = React.useState(null);
  const [allRows, setAllRows] = React.useState([]);
  const [searchKey, setSearchKey] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [currentRowIndex, setCurrentRowIndex] = React.useState(-1);
  const [userProgressNotesDialogVisible, setUserProgressNotesDialogVisible] = React.useState(false);
  // const [userProgressNotesEditDialogVisible, setUserProgressNotesEditDialogVisible] = React.useState(false);
  const [userHandoverNotesDialogVisible, setUserHandoverNotesDialogVisible] = React.useState(false);
  const [userFilesDialogVisible, setUserFilesDialogVisible] = React.useState(false);
  const [helpUploadDialogVisible, setHelpUploadDialogVisible] = React.useState(false);

  const fetchTutorRoomStudents = async ({daysOffset}) => {
    var res;
    try {
      setLoading(true);
      res = await getTutorRoomStudentsTodayList({daysOffset});
      setLoading(false);
      if (res && res.data) {
        if (res.data.list) {
          setStudentList(res.data.list);
        } else if (res.data.message) {
          Message.info(res.data.message);
        }
      } else {
        console.error(res.data);
        Message.error('Something is wrong!');
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      Message.error('Something is wrong!');
    }
  };

  const updateTutorRoomStudentProgressNote = async (progress_note_id, newNote) => {
    var res;
    try {
      setLoading(true);
      res = await updatePracticeRoomStudentProgressNote(progress_note_id, newNote);
      setLoading(false);
      if (res && res.data) {
        fetchTutorRoomStudents({ daysOffset });
        const MySwal = withReactContent(Swal);
        if (res.status == 200) {
          MySwal.fire({
            icon: 'success',
            text: 'Update completed successfully!'
          });
        }
      } else {
        console.error(res.data);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);

      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Error: '+err.response.data.error
      });
    }
  };

  const newTutorRoomStudentHandoverNote = async (participant_detail_id, userId, remarks, lock, lastLockedBy, practiceSubjectId, practiceRoomId, remarksEditedAt) => {
    var res;
    try {
      setLoading(true);
      res = await newPracticeRoomStudentHandoverNote(participant_detail_id, userId, remarks, lock, lastLockedBy, practiceSubjectId, practiceRoomId, remarksEditedAt);
      setLoading(false);
      if (res && res.data) {
        fetchTutorRoomStudents({ daysOffset });
        console.log(res);
        const MySwal = withReactContent(Swal);
        if (res.status == 200) {
          MySwal.fire({
            icon: 'success',
            text: 'Handover note created for the student!'
          });
        }
      } else {
        console.error(res.data);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);

      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Error: '+err.response.data.error
      });

    }
  };

  const updateTutorRoomStudentHandoverNote = async (participant_detail_id, student_user_id, subject_id, remarks) => {
    var res;
    try {
      setLoading(true);
      res = await updatePracticeRoomStudentHandoverNote(participant_detail_id, student_user_id, subject_id, remarks);
      setLoading(false);
      if (res && res.data) {
        fetchTutorRoomStudents({ daysOffset });
        const MySwal = withReactContent(Swal);
        if (res.status == 200) {
          MySwal.fire({
            icon: 'success',
            text: 'Update completed successfully!'
          });
        }
      } else {
        console.error(res.data);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Error: '+err.response.data.error
      });
    }
  };

  const newTutorRoomStudentProgressNote = async (participant_detail_id, student_user_id, subject_id, remarks) => {
    var res;
    try {
      setLoading(true);
      res = await newPracticeRoomStudentProgressNote(participant_detail_id, student_user_id, subject_id, remarks);
      setLoading(false);
      if (res && res.data) {
        fetchTutorRoomStudents({ daysOffset });
        const MySwal = withReactContent(Swal);
        if (res.status == 200) {
          MySwal.fire({
            icon: 'success',
            text: 'Update completed successfully!'
          });
        }
      } else {
        console.error(res.data);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Error: '+err.response.data.error
      });
    }
  };

  const fetchStudentsPracticeRecord = async ({userIds, today, studentList}) => {
    var res;
    try {
      setLoading(true);
      res = await getPracticeRoomSomedayStatsList({userIds, today});
      if (res && res.data) {
        if (res.data.stats) {
          setStatsList(res.data.stats);
          // merge data
          var i;
          for(i = 0; i < studentList.length; i++) {
            studentList[i].stats = res.data.stats[i];
          }
          const result = studentList.map(x => createRowData(x));
          setAllRows(result);
        } else if (res.data.message) {
          Message.info(res.data.message);
        }
      } else {
        console.error(res.data);
        Message.error('Something is wrong');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      Message.error('Something is wrong');
    }
  };

  // const handleDateChange = (date) => {
  //   setSelectDate(date);
  //   var diff = (new Date().getTime() - new Date(date).getTime()) / ( 1000 * 3600 * 24);
  //   diff = Math.floor(diff);
  //   setDaysOffset('-' + diff);
  // };

  const onClickDisplayFiles = async (data, row, column, index) => {
    setCurrentRowIndex(index);
    setLoading(true);
    try {
      setLoading(false);
      setUserFilesDialogVisible(true);
    } catch (e) {
      setLoading(false);
    }
  };

  const onClickHelpUploadFiles = async (data, row, column, index) => {
    setCurrentRowIndex(index);
    setHelpUploadDialogVisible(true);
  };

  const onClickDisplayProgressNotes = async (data, row, column, index) => {
    setCurrentRowIndex(index);
    setUserProgressNotesDialogVisible(true);
  };

  const onClickDisplayHandoverNotes = async (data, row, column, index) => {
    setCurrentRowIndex(index);
    setUserHandoverNotesDialogVisible(true);
  };

  const createFilter = (queryString) => {
    return (item) => {
      return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    };
  };

  useEffect(() => {
    if (!searchKey) {
      setRows(allRows);
    } else {
      const results = allRows.filter(createFilter(searchKey));
      setRows(results);
    }
  }, [searchKey, allRows]);


  useEffect(() => {
    fetchTutorRoomStudents({ daysOffset });
  }, [daysOffset]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year + '-' + month + '-' + day;
  };

  useEffect(() => {
    if (studentList && selectDate && !loading) {
      const userIds = studentList.map(x => x.user_id);
      const today = formatDate(new Date(selectDate));
      fetchStudentsPracticeRecord({ userIds, today, studentList });
    }
  }, [studentList, selectDate]);

  // function DatePicker(props) {
  //   return (
  //     <MuiPickersUtilsProvider utils={DateFnsUtils}>
  //       <KeyboardDatePicker
  //         disableToolbar
  //         variant='inline'
  //         format='MM/dd/yyyy'
  //         margin='normal'
  //         id='date-picker'
  //         label={t('tutoredStudents.tutoredDate')}
  //         value={selectDate}
  //         onChange={handleDateChange}
  //         KeyboardButtonProps={{
  //           'aria-label': 'change date',
  //         }}
  //       />
  //     </MuiPickersUtilsProvider>
  //   );
  // }

  // function ProfileList(props) {
  //   const { classes, profile } = props;

  //   const renderProfile = (data, style) => {
  //     const index = data.index;
  //     if (index === 0) {
  //       return (
  //         <ListItem dense={true}>
  //           <ListItemText primary={'Level:  ' + profile.level} />
  //         </ListItem>
  //       );
  //     } else if (index === 1) {
  //       return (
  //         <ListItem dense={true}>
  //           <ListItemText primary={'Goal:  ' + profile.goal} />
  //         </ListItem>
  //       );
  //     } else if (index === 2) {
  //       return (
  //         <ListItem dense={true}>
  //           <ListItemText primary={'Notes:  ' + profile.notes} />
  //         </ListItem>
  //       );
  //     } else if (index === 3) {
  //       return (
  //         <ListItem dense={true}>
  //           <ListItemText primary={'Teacher Name:  ' + profile.teacher_name} />
  //         </ListItem>
  //       );
  //     } else if (index === 4) {
  //       return (
  //         <ListItem dense={true}>
  //           <ListItemText primary={'Age: ' + profile.age} />
  //         </ListItem>
  //       );
  //     } else {
  //       return (
  //         <ListItem dense={true}>
  //           <ListItemText primary={'Device: ' + profile.device} />
  //         </ListItem>
  //       );
  //     }
  //   };

  //   return (
  //     <div className={classes.profile}>
  //       <FixedSizeList height={200} width={200} itemSize={6} itemCount={6}>
  //         {
  //           renderProfile
  //         }
  //       </FixedSizeList>
  //     </div>
  //   );
  // }

  const renderSearch = () => {
    return (
      <TextField
        label={t('tutoredStudents.studentName')}
        size="small"
        variant="outlined"
        value={searchKey}
        onChange={e => setSearchKey(e.target.value)}
      />
    );
  };

  const generateArray = (start, end) => {
    return Array.from(new Array(end).keys()).slice(start);
  };

  const onClickEditProgressNote = (item) => {
    var origNote = item.content;

    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <p>{t('tutoredStudents.editProgressNote')}</p>,
      input: 'textarea',
      showCancelButton: true,
      inputValue: origNote
    }).then((result) => {
      if (result.value && result.value != origNote){
        // call the Redux Action to update progress notes  
        updateTutorRoomStudentProgressNote(item.progress_note_id, result.value);
      }
    });
  };

  const onClickNewProgressNote = (profileId, studentUserId) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <p>{t('tutoredStudents.newProgressNote')}</p>,
      input: 'textarea',
      showCancelButton: true,
    }).then((result) => {
      if (result.value){
        // call the Redux Action to create new progress notes 
        newTutorRoomStudentProgressNote(profileId, studentUserId, result.value);
      }
    });
  };

  const onClickEditHandoverNote = (item) => {
    var origNote = item.remarks;

    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <p>{t('tutoredStudents.editHandoverNote')}</p>,
      input: 'textarea',
      showCancelButton: true,
      inputValue: origNote
    }).then((result) => {
      if (result.value && result.value != origNote){
        // call the Redux Action to update Handover Notes 
        updateTutorRoomStudentHandoverNote(item.participant_detail_id, item.student_user_id, item.subject_id, result.value);
      }
    });
  };

  const onClickNewHandoverNotes = (data, row, column, index) => {
    setCurrentRowIndex(index);

    const currentRowData = index >=0 ? rows[index] : null;
    console.log(1);
    console.log(currentRowData);

    if (!currentRowData) {
      return (<div />);
    }

    // const profileId = currentRowData.profiles.id;
    const studentUserId = currentRowData.id;
    const studentName = currentRowData.name;

    const participantDetails = currentRowData.participantDetails;
    const subjects = currentRowData.subjects;
    const subjectOptions = new Map;
    
    console.log(participantDetails);
    console.log(subjects);

    if (participantDetails != undefined) {
      participantDetails.forEach(item => subjectOptions.set(item.subject_id, item.subject));
    } else {
      subjects.forEach(item => subjectOptions.set(item.subject_id, item.subject));
    }
    // const MySwal = withReactContent(Swal);

    const steps = ['1', '2'];
    const swalQueueStep = Swal.mixin({
      confirmButtonText: 'Next',
      cancelButtonText: 'Back',
      progressSteps: steps,
      input: 'text',
      inputAttributes: {
        required: true
      },
      reverseButtons: true,
      validationMessage: 'This field is required'
    });
    async function backAndForth() {
      const values = [];
      let currentStep;
      for (currentStep = 0; currentStep < steps.length;) {
        let result = null;

        if (steps[currentStep] == 1) {
          result = await swalQueueStep.fire({
            title: 'Subject',
            input: 'select',
            inputOptions: subjectOptions,
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep
          });
        } else if (steps[currentStep] == 2) {
          result = await swalQueueStep.fire({
            title: 'New Handover Note for '+studentName,
            input: 'textarea',
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep
          });
        } else {
          break;
        }

        if (result.value) {
          values[currentStep] = result.value;
          currentStep++;
        } else if (result.dismiss === 'cancel') {
          currentStep--;
        } else {
          break;
        }

        if (currentStep === steps.length && values[1] != '') {
          // console.log(values[0]);
          if (participantDetails != null){
            var ppdEntry = participantDetails.find((element) => {
              // console.log(element);
              if (element.subject_id == values[0]) return element;
              else return 0;
            });
  
            var ppdId = '';
            if (ppdEntry != null) ppdId = ppdEntry.participant_detail_id;
            if (ppdId === 0) ppdId = null;
          } else {
            ppdId = null;
          } 

          var now = new Date();
          // newTutorRoomStudentHandoverNote(ppdId, studentUserId, result.value[1], lock, lastLockedBy, practiceSubjectId, practiceRoomId, remarksEditedAt);
          newTutorRoomStudentHandoverNote(ppdId, studentUserId, values[1], 0, 0, values[0], -1, now.toISOString());
        }
      }
    }
    backAndForth();
    /*
    MySwal.mixin({
      confirmButtonText: 'Next',
      showCancelButton: true,
      progressSteps: ['1', '2']
    }).queue([
      {
        title: 'Subject',
        input: 'select',
        inputOptions: subjectOptions
      },
      {
        title: 'New Handover Note for '+studentName,
        input: 'textarea'
      },
    ]).then((result) => {
      if (result.value && result.value[1] != ""){
        var ppdEntry = participantDetails.find((element) => {
          return element.participant_detail_id === result.value[0];
        });
      
        var ppdId = ppdEntry.participant_detail_id;

        newTutorRoomStudentHandoverNote(ppdId, studentUserId, result.value[1], '', 0, result.value[0], 0, '');
      }
    });
*/

  };

  const renderUserProgressNotesDialog = () => {
    const renderCard = (item) => {
      var editButton = item.canEdit ? (
        <Layout.Row type="flex" justify="end">
          <div className="bottom clearfix">
            <EleButton type="primary" size="small" onClick={(/* data */) => onClickEditProgressNote(item)}>
              {t('tutoredStudents.edit')}
            </EleButton>
          </div>
        </Layout.Row>
      ) : (
        <></>
      );

      return (
        <Card bodyStyle={{ padding: 20 }}>
          {
            <span style={{ padding: 0 }}>{ item.content } </span>
          }
          <div style={{ fontSize: '8px', marginTop: '10px' }}>
            <Layout.Row type="flex" justify="end">
              <span>{ item.FirstName + ' ' + item.LastName }</span>
            </Layout.Row>
            <Layout.Row type="flex" justify="end">
              <div className="bottom clearfix">
                <time className="time">{ item.date }</time>
              </div>
            </Layout.Row>
            {editButton}
          </div>
        </Card>
      );
    };

    const currentRowData = currentRowIndex >=0 ? rows[currentRowIndex] : null;
    if (!currentRowData) {
      return (<div />);
    }
    const notes = currentRowData.progressNotes;
    const length = notes ? notes.length : 0;
    const columnCount = 4;
    const rowCount = length % columnCount ? Math.floor(length / columnCount) + 1 : Math.floor(length / columnCount);

    const profileId = currentRowData?.profiles?.id;
    const studentUserId = currentRowData.id;

    return (
      <Dialog
        size={ length > 0 ? 'large' : 'small' }
        title={t('tutoredStudents.userProgressNoteDialog.title')}
        visible={ userProgressNotesDialogVisible }
        onCancel={ () => setUserProgressNotesDialogVisible(false) }
      >
        <Dialog.Body>
          <div>
            {
              <Layout.Row type="flex" justify="center">
                <div className="">
                  <EleButton type="primary" size="small" onClick={(/* data */) => onClickNewProgressNote(profileId, studentUserId)}>
                    {t('tutoredStudents.newProgressNote')}
                  </EleButton>
                </div>
              </Layout.Row>
            }
            { length === 0 && (
              <span>
                { t('tutoredStudents.userProgressNoteDialog.noNotes')}
              </span>
            )}
            {
              generateArray(0, rowCount).map(rowIndex =>(
                <Layout.Row key={rowIndex} gutter="20" style={{marginTop: '10px'}}>
                  {
                    (generateArray(
                      0,
                      (rowIndex < rowCount - 1
                        ?
                        columnCount
                        :
                        (rowCount > 1 ? length % columnCount : length)
                      )
                    )).map(columnIndex => (
                      <Layout.Col
                        key={rowIndex * columnCount + columnIndex}
                        span={24/columnCount}
                      >
                        {
                          renderCard(notes[rowIndex * columnCount + columnIndex])
                        }
                      </Layout.Col>
                    ))
                  }
                </Layout.Row>
              ))
            }
          </div>
        </Dialog.Body>
      </Dialog>
    );
  };

  const renderUserHandoverNotesDialog = () => {
    const renderCard = (item) => {
      var editHandoverButton = item.canEdit ? (
        <Layout.Row type="flex" justify="end">
          <div className="bottom clearfix">
            <EleButton type="primary" size="small" onClick={(/* data */) => onClickEditHandoverNote(item)}>
              {t('tutoredStudents.edit')}
            </EleButton>
          </div>
        </Layout.Row>
      ) : (
        <></>
      );

      return (
        <Card header={item.subject} bodyStyle={{ padding: 20 }}>
          {
            <span style={{ padding: 0 }}>{ item.remarks } </span>
          }
          <div style={{ fontSize: '8px', marginTop: '10px' }}>
            <Layout.Row type="flex" justify="end">
              <span>{ item.lastEditedBy }</span>
            </Layout.Row>
            <Layout.Row type="flex" justify="end">
              <div className="bottom clearfix">
                <time className="time">{ item.date }</time>
              </div>
            </Layout.Row>
            {editHandoverButton}
          </div>
        </Card>
      );
    };

    const currentRowData = currentRowIndex >=0 ? rows[currentRowIndex] : null;
    if (!currentRowData) {
      return (<div />);
    }
    const notes = currentRowData.handoverNotes;
    const length = notes?.length || 0;
    const columnCount = 4;
    const rowCount = length % columnCount ? Math.floor(length / columnCount) + 1 : Math.floor(length / columnCount);

    return (
      <Dialog
        size={ length  > 0 ? 'large' : 'small' }
        title={t('tutoredStudents.userHandoverNoteDialog.title')}
        visible={ userHandoverNotesDialogVisible }
        onCancel={ () => setUserHandoverNotesDialogVisible(false) }
      >
        <Dialog.Body>
          <div>
            { length === 0 && (
              <span>
                { t('tutoredStudents.userHandoverNoteDialog.noNotes')}
              </span>
            )}
            {
              generateArray(0, rowCount).map(rowIndex =>(
                <Layout.Row key={rowIndex} gutter="20" style={{ marginTop: '10px'}}>
                  {
                    (generateArray(
                      0,
                      (rowIndex < rowCount - 1
                        ?
                        columnCount
                        :
                        (rowCount > 1 ? length % columnCount : length)
                      )
                    )).map(columnIndex => (
                      <Layout.Col
                        key={rowIndex * columnCount + columnIndex}
                        span={24/columnCount}
                      >
                        {
                          renderCard(notes[rowIndex * columnCount + columnIndex])
                        }
                      </Layout.Col>
                    ))
                  }
                </Layout.Row>
              ))
            }
          </div>
        </Dialog.Body>
      </Dialog>
    );
  };

  const renderHelpUploadDialog = () => {
    const currentRow = currentRowIndex >=0 ? rows[currentRowIndex] : null;
    if (!currentRow) {
      return (null);
    }

    // const userId = currentRow.id;
    // const userNickname = currentRow.name;
    const profileId = currentRow?.profiles?.id;
  
    return (
      <Dialog
        customClass="userFilesDialog"
        modal={false}
        size={ 'small' }
        title={
          t('userFileDialog.fileUploader.helpUploadFile')
        }
        visible={ helpUploadDialogVisible }
        onCancel={ () => setHelpUploadDialogVisible(false) }
      >
        <Dialog.Body>
          {
            <FileUploader
              limit={5}
              profileId={profileId}
              // eslint-disable-next-line no-unused-vars
              callback={( err, data) => {
              }}
              uploadMine={false}
            />
          }
        </Dialog.Body>
      </Dialog>
    );
  };

  const renderTable = () => {
    const columns = [
      {
        label: t('tutoredStudents.student'),
        prop: 'name',
        sortable: true,
        width: 220,
        fixed: 'left'
      },
//      {
//        label: t('tutoredStudents.country'),
//        prop: 'country',
//        sortable: true,
//        width: 120
//      },
      {
        label: t('tutoredStudents.subject'),
        prop: 'subject',
        sortable: true,
        width: 200
      },
      {
        label: t('tutoredStudents.objective'),
        prop: 'objectives',
        width: 250
      },
      {
        label: t('tutoredStudents.operations'),
        // eslint-disable-next-line react/display-name
        render: (row, column, index) => {
          return (
            <span>
              {
                row.has_files
                  ?
                  <EleButton type="success" size="small" onClick={(data) => onClickDisplayFiles(data, row, column, index)}>
                    {t('tutoredStudents.files')}
                  </EleButton>
                  :
                  <EleButton type="danger" size="small" onClick={(data) => onClickHelpUploadFiles(data, row, column, index)}>
                    {t('userFileDialog.fileUploader.helpUploadFile')}
                  </EleButton>
              }
              <EleButton type="info" size="small" onClick={(data) => onClickDisplayProgressNotes(data, row, column, index)}>
                {t('tutoredStudents.progressNotes')}
              </EleButton>
              {
                row.handoverNotes === null || row.handoverNotes.length == 0 ?
                  <EleButton type="danger" size="small" onClick={(data) => onClickNewHandoverNotes(data, row, column, index)}>
                    {t('tutoredStudents.newHandoverNote')}
                  </EleButton>
                  :
                  <EleButton type="info" size="small" onClick={(data) => onClickDisplayHandoverNotes(data, row, column, index)}>
                    {t('tutoredStudents.handoverNotes')}
                  </EleButton>
              }
            </span>
          );
        }
      }
    ];

    return (
      <Table
        style={{width: '100%'}}
        columns={columns}
        data={rows}
        border={true}
        stripe={true}
      />
    );
  };

  const renderUserFilesDialog = () => {
    const currentRow = currentRowIndex >=0 ? rows[currentRowIndex] : null;
    if (!currentRow) {
      return (null);
    }
    
    return (
      <div>
        <UserFilesDialog
          visible={userFilesDialogVisible}
          setVisible={setUserFilesDialogVisible}
          userId={currentRow.id}
          userNickname={currentRow.name}
          profileId={currentRow?.profiles?.id}
        />
      </div>
    );
  };

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
        <Paper className={classes.paper}>
          <Layout.Row type="flex" justify="space-between">
            <Layout.Col span="6">
              <Typography variant='h6'>
                {t('tutoredStudents.tutoredStudents')}
                <Button
                  disabled={props.practiceRoomsLoading}
                  className={classes.refreshBtn}
                  color='primary'
                  onClick={() => {
                    fetchTutorRoomStudents({ daysOffset });
                  }}
                >
                  {t('refresh')}
                </Button>
              </Typography>
            </Layout.Col>
            <Layout.Col span="4">
              { renderSearch() }
            </Layout.Col>
          </Layout.Row>
          {/* <DatePicker />*/}
          { renderTable() }
        </Paper>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      { renderUserFilesDialog() }
      { renderUserProgressNotesDialog() }
      { renderUserHandoverNotesDialog() }
      { renderHelpUploadDialog() }
    </Container>
  );
};

export default TutoredStudentsScreen;
