import React, {
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import {
  // Table,
  Dialog,
  Card,
  Layout,
  Button as EleButton,
  // Message,
  Pagination,
  Loading,
  Message,
} from 'element-react';
import 'element-theme-default';
import { i18n } from 'element-react';
import locale from 'element-react/src/locale/lang/en';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {
// updateCommonStates,
// getTutorRoomStudentsTodayListAction,
} from '../../actions';
import {
  deletePracticeRoomFiles,
  // getTutorRoomStudentsTodayList,
  // getPracticeRoomSomedayStatsList,
  getPracticeRoomFiles,
  getPracticeRoomProfile,
} from '../../api';
// import GLOBAL from '../../helpers/GLOBAL';
import FileUploader from './FileUploader';

i18n.use(locale);

const UserFilesDialog = (props) => {
  const { t } = useTranslation();
  // const user = GLOBAL.get('user');

  const {
    userId,
    userNickname,
    profileId,
    visible,
    setVisible,
    isMyFiles,
  } = props;
 
  const [loading, setLoading] = React.useState(false);
  const [userFiles, setUserFiles] = React.useState([]);
  const [largeImageDialogVisible, setLargeImageDialogVisible] = React.useState(false);
  const [deleteFileDialogVisible, setDeleteFileDialogVisible] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState(null);
  const [pageTotal, setPageTotal] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const largeImageDialogBodyRef = React.useRef();
  const [pageWidth, setPageWidth] = React.useState(0);

  const fetchGetPracticeRoomFiles = async ({practiceRoomProfileId}) => {
    var res;
    try {
      res = await getPracticeRoomFiles({ practiceRoomProfileId });
      if (res && res.data) {
        if (res.data.files) {
          return res.data.files;
        }
      } else {
        console.error(res.data);
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const requestGetPracticeRoomFiles = async (userId, profileId) => {
    if (!profileId) {
      const response = await getPracticeRoomProfile({ userId }); // work only for getting my own profileId!
      if (!response || !response.data || !response.data.practiceRoomProfile) {
        return null;
      }
      profileId = response.data.practiceRoomProfile.id;
    }
    const files = await fetchGetPracticeRoomFiles({ practiceRoomProfileId: profileId });
    if (files) {
      return files;
    } else {
      return [];
    }
  };

  const fetchDeletePracticeRoomFiles = async ({ practiceRoomFileIds }) => {
    var res;
    try {
      res = await deletePracticeRoomFiles({ practiceRoomFileIds });
      if (res && res.data) {
        return res.data.success;
      } else {
        console.error(res.data);
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const requestDeletePracticeRoomFiles = async ({ practiceRoomFileIds }) => {
    const success = await fetchDeletePracticeRoomFiles({ practiceRoomFileIds });
    return success;
  };


  const updateData = async () => {
    setLoading(true);
    try {
      const files = await requestGetPracticeRoomFiles(userId, profileId);
      setUserFiles(files);
      setLoading(false);
    } catch (e) {
      setUserFiles([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible && (userId || profileId)) {
      updateData();
    }
  }, [visible, userId, profileId]);

  useEffect(() => {
    if (largeImageDialogVisible) {
      const bodyWidth = largeImageDialogBodyRef.current.clientWidth;
      setPageWidth(bodyWidth);
    }
  }, [largeImageDialogVisible]);

  const generateArray = (start, end) => {
    return Array.from(new Array(end).keys()).slice(start);
  };

  const onDocumentLoadSuccess = ({ pageTotal }) => {
    setPageTotal(pageTotal);
  };

  const onClickOpenFile = (item) => {
    setCurrentFile(item);
    if (item.file_extension === 'pdf') {
      setPageNumber(1);
    }
    if (item && item.uri) {
      setLargeImageDialogVisible(true);
    }
  };

  const onClickDeleteFile = (item) => {
    setCurrentFile(item);
    setDeleteFileDialogVisible(true);
  };
  
  const onClickConfirmDeleteFile = async (item) => {
    setDeleteFileDialogVisible(false);
    const res = await requestDeletePracticeRoomFiles({
      practiceRoomFileIds: [item.id]
    });
    if (res) {
      Message.info(t('userFileDialog.deleteFile.successMessage'));
    } else {
      Message.error(t('userFileDialog.deleteFile.errorMessage'));
    }
    updateData();
  };

  const renderLargeImageDialog = () => {
    if (!largeImageDialogVisible || !currentFile || !currentFile.uri) {
      return (
        <div />
      );
    }

    return (
      <Dialog
        customClass="largeImageDialog"
        modal={ false }
        size={ 'small' }
        visible={ largeImageDialogVisible }
        onCancel={ () => setLargeImageDialogVisible(false) }
      >
        <Dialog.Body>
          <div ref={largeImageDialogBodyRef}>
            {
              currentFile.file_extension === 'pdf'
                ?
                <div>
                  <Pagination
                    layout="prev, pager, next, jumper"
                    pageCount={pageTotal}
                    currentPage={pageNumber}
                    onCurrentChange={(currentPage) => setPageNumber(currentPage)}
                  />
                  <Document
                    file={currentFile.uri}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={pageNumber}
                      width={pageWidth}
                    />
                  </Document>
                </div>
                :
                <img src={currentFile.uri} alt={currentFile.display_name + '.' + currentFile.file_extension} style={{ display: 'inline-block', height: 'auto', maxWidth: '100%'}} />
            }
          </div>
        </Dialog.Body>
      </Dialog>
    );
  };

  const renderDeleteFileDialog = () => {
    if (!deleteFileDialogVisible || !currentFile) {
      return (
        <div />
      );
    }

    return (
      <Dialog
        customClass="largeImageDialog"
        modal={ false }
        size={ 'small' }
        title={ t('userFileDialog.deleteFile.title')}
        visible={ deleteFileDialogVisible }
        lockScroll={ false }
        onCancel={ () => setDeleteFileDialogVisible(false) }
      >
        <Dialog.Body>
          <span>
            { t('userFileDialog.deleteFile.title') + ' ' + currentFile.display_name + '.' + currentFile.file_extension + ' ?'}
          </span>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <EleButton onClick={ () => setDeleteFileDialogVisible(false) }>{ t('alert.cancel') }</EleButton>
          <EleButton type="primary" onClick={ () => onClickConfirmDeleteFile(currentFile) }>{ t('alert.confirm') }</EleButton>
        </Dialog.Footer>
      </Dialog>
    );
  };


  const renderCard = (item) => {
    return (
      <Card bodyStyle={{ padding: 0 }}>
        {
          item.file_extension === 'pdf'
            ?
            <div>
              <Layout.Row type="flex" justifyContent="center">
                <a
                  style={{ padding: 24 }}
                  href={item.uri}
                  target="_blank"
                  rel="noopener noreferrer">
                  {item.display_name + '.' + item.file_extension}
                </a>
              </Layout.Row>
            </div>
            :
            <img src={item.uri} alt={item.display_name + '.' + item.file_extension} onClick={() => onClickOpenFile(item) } style={{ display: 'inline-block', height: 'auto', maxWidth: '100%'}} />
        }
        <div style={{ fontSize: '8px', marginTop: '10px', marginLeft: 'auto' }}>
          <Layout.Row type="flex" justifyContent="end">
            <span>{ item.display_name + '.' + item.file_extension }</span>
          </Layout.Row>
          <Layout.Row type="flex" justifyContent="end">
            <div className="bottom clearfix">
              <time className="time">{ item.added_at }</time>
              <div>
                <EleButton type="primary" size="mini" onClick={() => onClickOpenFile(item) }>
                  {t('userFileDialog.open')}
                </EleButton>
                <EleButton type="danger" size="mini" onClick={() => onClickDeleteFile(item) }>
                  {t('userFileDialog.delete')}
                </EleButton>
                <a
                  style={{ padding: 12 }}
                  href={item.uri}
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('userFileDialog.download')}
                </a>
              </div>
            </div>
          </Layout.Row>
        </div>
      </Card>
    );
  };

  const length = userFiles ? userFiles.length : 0;
  const columnCount = 4;
  const rowCount = length % columnCount ? Math.floor(length / columnCount) + 1 : Math.floor(length / columnCount) + 1;

  return (
    <div>
      <Dialog
        customClass="userFilesDialog"
        modal={false}
        size={ 'large' }
        title={
          isMyFiles
            ?
            t('userFileDialog.myFiles')
            :
            (
              userNickname
                ?
                userNickname
                :
                t('userFileDialog.title')
            )
        }
        visible={ visible }
        onCancel={ () => setVisible(false) }
      >
        <Dialog.Body>
          {
            <FileUploader
              limit={5}
              profileId={profileId}
              // eslint-disable-next-line no-unused-vars
              callback={( err, data) => {
                updateData();
              }}
              uploadMine={isMyFiles}
            />
          }
          <Loading loading={loading}>
            { length === 0 && (
              <span>
                {t('userFileDialog.noFiles')}
              </span>
            )}
            {
              generateArray(0, rowCount).map(rowIndex =>(
                <Layout.Row key={rowIndex} gutter="20" style={{ marginTop: '10px'}}>
                  {
                    (generateArray(
                      0,
                      (rowIndex < rowCount - 1
                        ?
                        columnCount
                        :
                        (rowCount > 1 ? length % columnCount : length)
                      )
                    )).map(columnIndex => (
                      <Layout.Col
                        key={rowIndex * columnCount + columnIndex}
                        span={24/columnCount}
                      >
                        {
                          renderCard(userFiles[rowIndex * columnCount + columnIndex])
                        }
                      </Layout.Col>
                    ))
                  }
                </Layout.Row>
              ))
            }
          </Loading>
        </Dialog.Body>
      </Dialog>
      { renderLargeImageDialog() }
      { renderDeleteFileDialog() }
    </div>
  );
  
};

export default UserFilesDialog;
